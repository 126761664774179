import PlayerFormModal from '../PlayerFormModal';
import http from '../../../../services/http';

function EditPlayerModal({ show, onClose, onEditSuccess, player }) {
  const onEditPlayer = _player => {
    http.put(`/players/${player.id}`, _player)
      .then(() => {
        onEditSuccess();
        onClose();
      });
  }

  return (
    <PlayerFormModal title="Edit player" show={show} onClose={onClose} onSave={onEditPlayer} player={player}/>
  );
}

export default EditPlayerModal;