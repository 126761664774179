import { useEffect, useState } from 'react';
import { VscFilter } from 'react-icons/vsc';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Filters from '../components/Filters';
import http from '../services/http';

function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState(null);
  const [filters, setFilters] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const fetchLeaderboard = (filterState = {}) => {
    http.get('/leaderboard', { params: filterState })
      .then(res => setLeaderboard(res.data.data));
  }

  const fetchFilters = () => {
    http.get('/leaderboard/filters')
      .then(res => res.data.data)
      .then(_filters => _filters.filter(f => f.options.length > 0))
      .then(_filters => setFilters(_filters));
  }

  useEffect(() => {
    fetchLeaderboard();
    fetchFilters();
  }, []);

  const onFilterStateChange = filterState => {
    fetchLeaderboard(filterState);
  }

  return (
    <>
      <h1 className="text-center pb-2">Leaderboard</h1>
      { leaderboard && filters &&
        <>
          <VscFilter className="filter-icon" onClick={() => setShowFilters(!showFilters)}/>

          <Row className="flex-md-nowrap">
            <Col md="auto" sm="12" className={`mr-md-4 mb-4 mb-md-0 ${!showFilters ? 'd-none' : ''}`}>
              <Filters filters={filters} onFilterStateChange={onFilterStateChange}/>
            </Col>

            <Col style={{'width': 0}}>
              <Row>
                { leaderboard.map(l =>
                  <Col md="3" sm="12" className="mb-4">
                    <Card className="h-100">
                      <Card.Body>
                        <Card.Title>{l.track.name} ({l.track.gp_name})</Card.Title>
                        <Card.Body>
                          { l.top 
                            ? <>
                                <span className="font-weight-bold">{l.top.player.username}</span><br/>
                                <span className="font-weight-light">{l.top.time} s</span>
                              </>
                            : <span>-</span>
                          }
                        </Card.Body>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                <Col md="3" sm="6">

                </Col>
              </Row>
            </Col>
          </Row>
        </>
      }
    </>
  );
}

export default Leaderboard;