import { useState } from 'react';
import ApiTokenFormModal from '../ApiTokenFormModal';
import http from '../../../../services/http';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function AddApiTokenModal({ show, onClose, onAddSuccess }) {
  const [token, setToken] = useState(null);
  const [showTokenModal, setShowTokenModal] = useState(false);

  const onAddApiToken = apiToken => {
    http.post('/auth/api-tokens', apiToken)
      .then(res => {
        setToken(res.data.data.token);
        setShowTokenModal(true);
        onAddSuccess();
        onClose();
      });
  }

  return (
    <>
      <ApiTokenFormModal title="Add API token" show={show} onClose={onClose} onSave={onAddApiToken}/>
      <Modal show={showTokenModal} onHide={() => setShowTokenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Created token</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This is your newly created token:</p>
          <p className="text-center"><code>{token}</code></p> 
          <p>You will not have access to this token after you close this modal.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowTokenModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddApiTokenModal;