import Container from 'react-bootstrap/Container';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useEffect, useState } from 'react';
import authContext from './contexts/authContext';
import { isLoggedIn as httpIsLoggedIn, init as initHttp, axios } from './services/http';
import NavHeader from './components/NavHeader';
import Admin from './pages/Admin';
import LapData from './pages/LapData';
import Login from './pages/Login';
import Leaderboard from './pages/Loaderboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(httpIsLoggedIn);
  const [user, setUser] = useState(null);

  useEffect(() => {
    initHttp(() => {
      setIsLoggedIn(false);
      setUser(null);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      axios.get('/auth/me')
        .then(res => setUser(res.data.data))
        .catch(() => {});
    }
  }, [isLoggedIn]);

  return (
    <authContext.Provider value={{isLoggedIn, setIsLoggedIn}}>
      <Router>
        <NavHeader user={user}/>
        <Container fluid className="main-container">
        <Switch>
          <Route exact path="/">
            <Redirect to="/laps"/>
          </Route>

          <Route exact path="/laps">
            <LapData/>
          </Route>

          <Route exact path="/leaderboard">
            <Leaderboard/>
          </Route>

          <Route exact path="/login">
            <Login/>
          </Route>

          <Route exact path="/admin">
            <Admin/>
          </Route>
        </Switch>
        </Container>
      </Router>
    </authContext.Provider>
  );
}

export default App;
