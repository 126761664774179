import axios from 'axios';
import config from '../config';

const axiosInst = axios.create({
  baseURL: config.apiUrl
});

let onLogout = null;

function init(_onLogout) {
  onLogout = _onLogout;
}

function isLoggedIn() {
  return getToken() !== null;
}

function getToken() {
  return localStorage.getItem('token');
}

function setToken(token) {
  localStorage.setItem('token', token);
}

let interceptor;

if (isLoggedIn()) {
  axiosInst.defaults.headers.common['X-Authorization'] = `Bearer ${getToken()}`;
  enableInterceptor();
}

function afterLogin(token) {
  setToken(token);
  axiosInst.defaults.headers.common['X-Authorization'] = `Bearer ${token}`;
  enableInterceptor();
}

function enableInterceptor() {
  interceptor = axiosInst.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  });
}

function logout() {
  localStorage.removeItem('token');
  axiosInst.interceptors.response.eject(interceptor);
  if (onLogout) {
    onLogout();
  }
  // TODO: go to login page
  // TODO: update authContext
}

export default axiosInst;

export { isLoggedIn, afterLogin, logout, init, axiosInst as axios}