import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { VscEdit, VscTrash } from 'react-icons/vsc';

// fields: [{ name, key }], data: [{...}]
function DataTable({ fields, data, sorting = false, edit = false, remove = false, onEdit = null, onRemove = null }) {
  const SORTING_TYPES = ['default', 'ascending', 'descending'];

  const [currentSorting, setCurrentSorting] = useState({
    field: null,
    type_index: 0
  });

  const getValue = (row, field) => {
    const val = getRawValue(row, field);
    return field.render !== undefined ? field.render(val) : val;
  }

  const getSortingValue = (row, field) => {
    const val = getRawValue(row, field);
    return field.sortKey !== undefined ? field.sortKey(val) : val;
  }

  const getRawValue = (row, field) => {
    return field.key.split('.').reduce((p, c) => p?.[c], row);
  }

  const onHeaderClick = field => {
    setCurrentSorting({
      field: field,
      type_index: currentSorting.field && currentSorting.field.key === field.key ? (currentSorting.type_index + 1) % SORTING_TYPES.length : 1
    });
  }

  const sorter = (a, b) => {
    if (currentSorting.field === null || SORTING_TYPES[currentSorting.type_index] === 'default') {
      return 0;
    }
    const valA = getSortingValue(a, currentSorting.field);
    const valB = getSortingValue(b, currentSorting.field);
    console.log(valA, valB);

    if (valA < valB) {
      return SORTING_TYPES[currentSorting.type_index] === 'ascending' ? 1 : -1;
    }

    if (valA > valB) {
      return SORTING_TYPES[currentSorting.type_index] === 'ascending' ? -1 : 1;
    }

    return 0;
  }

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          {fields.map(field => 
            <th key={field.key} className={sorting ? 'pointer' : ''} onClick={() => sorting ? onHeaderClick(field) : null}>
              {field.name}
              {sorting && currentSorting.field && currentSorting.field.key === field.key
                ?
                  <>
                    {SORTING_TYPES[currentSorting.type_index] === 'ascending' && <span className="ml-1">&#8593;</span>}
                    {SORTING_TYPES[currentSorting.type_index] === 'descending' && <span className="ml-1">&#8595;</span>}
                    {SORTING_TYPES[currentSorting.type_index] === 'default' && <span className="ml-1 invisible">&#8593;</span>}
                  </>
                :
                  <span className="ml-1 invisible">&#8593;</span>
              }
            </th>
          )}

          {(edit || remove) && 
            <th>Actions</th>
          }
        </tr>
      </thead>
      <tbody>
        {data.slice().sort(sorter).map((row, index) =>
          <tr key={index}>
            {fields.map(field => 
              <td key={field.key}>
                {getValue(row, field)}
              </td>
            )}

            {(edit || remove) && 
              <td>
                {edit &&
                  <VscEdit className="pointer" onClick={() => onEdit(row)}/>
                }

                {remove &&
                  <VscTrash className={`pointer ${edit ? 'ml-2' : ''}`} onClick={() => onRemove(row)}/>
                }
              </td>
            }
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default DataTable;