import moment from 'moment';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { VscFilter } from 'react-icons/vsc';
import DataTable from '../components/DataTable';
import http from '../services/http';
import Filters from '../components/Filters';

function LapData() {
  const [laps, setLaps] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [filters, setFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    http.get('/tracks')
      .then(res => setTracks(res.data.data));
  }, []);

  useEffect(() => {
    if (!selectedTrack) return;
    fetchLaps();
    fetchFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrack]);

  const fetchLaps = (filterState = {}) => {
    http.get(`/tracks/${selectedTrack.id}/laps`, { params: filterState})
      .then(res => res.data.data)
      .then(laps => laps.map((lap, index) => ({ ...lap, number: index + 1 })))
      .then(laps => setLaps(laps));
  }

  const fetchFilters = () => {
    http.get(`/tracks/${selectedTrack.id}/laps/filters`)
      .then(res => res.data.data)
      .then(_filters => _filters.filter(f => f.options.length > 0))
      .then(_filters => setFilters(_filters));
  }

  const onSelectedTrackChange = e => setSelectedTrack(tracks[e.target.value]);

  const onFilterStateChange = filterState => {
    fetchLaps(filterState);
  }

  const fields = [
    { name: 'No.', key: 'number' },
    { name: 'Player', key: 'player.username' },
    { name: 'Time (s)', key: 'time', sortKey: val => parseFloat(val) },
    { name: 'Time Sector 1 (s)', key: 'time_sector_1', render: val => val.toFixed(3) },
    { name: 'Time Sector 2 (s)', key: 'time_sector_2', render: val => val.toFixed(3) },
    { name: 'Time Sector 3 (s)', key: 'time_sector_3', render: val => val.toFixed(3) },
    { name: 'Timestamp', key: 'timestamp', render: val => moment(val * 1000).format('DD-MM-YYYY HH:mm:ss') },
    { name: 'Driver', key: 'driver.name' },
    { name: 'Team', key: 'team.name' },
    { name: 'Formula', key: 'formula.name' },
    { name: 'Session Type', key: 'session_type.name' },
    { name: 'Weather', key: 'weather.name' },
    { name: 'Tyre', key: 'tyre.name' },
    { name: 'DRS Used', key: 'drs_used', render: val => val ? 'Yes' : 'No' },
    { name: 'Overtake Button Used', key: 'overtake_button_used', render: val => val ? 'Yes' : 'No' }
  ];

  return (
    <>
      <h1 className="text-center pb-2">Lap data</h1>
      <Form>
        <Form.Group controlId="selectedTrack">
          <Form.Label>Track</Form.Label>
          <Form.Control as="select" defaultValue="--Select track--" onChange={onSelectedTrackChange}>
            <option disabled>--Select track--</option>
            {tracks.map((track, index) => 
              <option key={track.id} value={index}>{track.name} ({track.gp_name})</option>
            )}
          </Form.Control>
        </Form.Group>
      </Form>

      { selectedTrack &&
        (laps.length > 0 || filters.length > 0 
          ?
            <>
              <VscFilter className="filter-icon" onClick={() => setShowFilters(!showFilters)}/>

              <Row className="flex-md-nowrap">
                <Col md="auto" sm="12" className={`mr-md-4 mb-4 mb-md-0 ${!showFilters ? 'd-none' : ''}`}>
                  <Filters filters={filters} onFilterStateChange={onFilterStateChange}/>
                </Col>
                <Col style={{'width': 0}}>
                  <DataTable fields={fields} data={laps} sorting/>
                </Col>
              </Row>
            </>
          :
            <p className="text-center">No data</p>
        )
      }
    </>
  );
}

export default LapData;