import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from 'react-router-dom';
import { VscAccount } from 'react-icons/vsc';
import { useContext, useEffect, useState } from 'react';

import { logout, axios } from '../../services/http';
import authContext from '../../contexts/authContext';
import './index.scss';
import SessionModal from '../admin/session/SessionModal';

function NavHeader({ user }) {
  const { isLoggedIn, setIsLoggedIn } = useContext(authContext);
  const [session, setSession] = useState(null);
  const [showSessionModal, setShowSessionModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) return;
    axios.get('/session')
      .then(res => setSession(res.data.data))
      .catch(() => {});
  }, [isLoggedIn]);

  const getUserName = () => {
    return !!user ? `${user.first_name} ${user.last_name}` : ''
  }

  const onLogout = () => {
    setIsLoggedIn(false);
    logout();
  }

  const onSessionModalShow = () => {
    setShowSessionModal(true);
  }

  const onSessionModalClose = () => {
    setShowSessionModal(false);
  }

  const onSaveSession = _session => {
    axios.post('/session', _session)
      .then(res => {
        setSession(res.data.data);
        onSessionModalClose();
      }).catch(() => {});
  }

  return (
    <>
      <Navbar bg="primary" expand="lg">
        <LinkContainer to="/">
          <Navbar.Brand>
            <img src="/images/f1_logo.svg" alt="F1 logo" height="30"/>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-collapse"/>
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <LinkContainer to="/laps">
              <Nav.Link className="nav-item">Lap data</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/leaderboard">
              <Nav.Link className="nav-item">Leaderboard</Nav.Link>
            </LinkContainer>

            {isLoggedIn && 
              <LinkContainer to="/admin">
                <Nav.Link className="nav-item">Admin</Nav.Link>
              </LinkContainer>
            }
          </Nav>

          {!isLoggedIn &&
            <Button variant="secondary" onClick={() => history.push('/login')}>Login</Button>
          }
          {isLoggedIn &&
            <>
              <Button variant="secondary" className="mr-2" onClick={onSessionModalShow}>
              P1 - {(!!session && session.player_1?.username) || 'CPU'} | P2 - {(!!session && session.player_2?.username) || 'CPU'}
              </Button>
              <Dropdown>
                <Dropdown.Toggle>
                  <VscAccount style={{fontSize: '2rem', color: 'white'}}/>
                </Dropdown.Toggle>
                <Dropdown.Menu align={{ lg: 'right' }}>
                  <Dropdown.Item className="user-name" disabled>{getUserName()}</Dropdown.Item>
                  <Dropdown.Divider/>
                  <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          }
        </Navbar.Collapse>
      </Navbar>
      {isLoggedIn && 
        <SessionModal show={showSessionModal} onClose={onSessionModalClose} onSave={onSaveSession} session={session}/>
      }
    </>
  )
}

export default NavHeader;