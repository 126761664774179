import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import http from '../../../../services/http';

function SessionModal({ show, onClose, onSave, session }) {
  const [players, setPlayers] = useState([]);
  const [_session, _setSession] = useState(null);

  useEffect(() => {
    http.get('/players')
      .then(res => setPlayers(res.data.data));
  }, []);

  useEffect(() => {
    _setSession({
      player_1_id: session?.player_1?.id || null,
      player_2_id: session?.player_2?.id || null
    });
  }, [session]);

  const onSetPlayer1 = e => {
    _setSession({
      ..._session,
      player_1_id: e.target.value
    });
  }

  const onSetPlayer2 = e => {
    _setSession({
      ..._session,
      player_2_id: e.target.value
    });
  }

  const onSaveSession = () => {
    onSave({
      player_1_id: _session.player_1_id || null,
      player_2_id: _session.player_2_id || null
    });
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manage session</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table borderless>
          <tbody>
            <tr>
              <th>Player 1</th>
              <td>
                <Form.Control as="select" defaultValue={(_session && _session.player_1_id) || ''} onChange={onSetPlayer1}>
                  <option value="">CPU</option>
                  {players.map(player =>
                    <option key={player.id} value={player.id}>{player.username}</option>
                  )}
                </Form.Control>
              </td>
            </tr>
            <tr>
              <th>Player 2</th>
              <td>
                <Form.Control as="select" defaultValue={(_session && _session.player_2_id) || ''} onChange={onSetPlayer2}>
                <option value="">CPU</option>
                {players.map(player => 
                  <option key={player.id} value={player.id}>{player.username}</option>
                )}
                </Form.Control>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={onSaveSession}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SessionModal;