import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';

// filters: [{ name, key, multiple, options=[{ name, value }] }]
function Filters({ filters, onFilterStateChange }) {
  const [filterState, setFilterState] = useState({});

  useEffect(() => {
    setFilterState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterStateChange = (filter, value, checked) => {
    const values = filterState[filter.key] || [];
    let newValues;
    if (checked) {
      if (filter.multiple) {
        newValues = values.slice();
        newValues.push(value);
      } else {
        newValues = [value];
      }
    } else {
      if (filter.multiple) {
        newValues = values.filter(v => v !== value);
      } else {
        newValues = [];
      }
    }

    const newState = {
      ...filterState,
      [filter.key]: newValues
    }

    setFilterState(newState);
    onFilterStateChange(newState);
  }

  const resetFilters = () => {
    setFilterState({});
    onFilterStateChange({});
  }

  const isFilterStateEmpty = () => {
    for (const property in filterState) {
      if (filterState[property].length > 0) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <Button variant="link" className="x-btn-link mb-2" onClick={resetFilters} disabled={isFilterStateEmpty()}>Reset filters</Button>
      {filters.map((filter, index) => 
        <Row key={filter.key} className="pb-2">
          <Col>
            <Filter filter={filter} filterState={filterState[filter.key] || []} onFilterStateChange={(...params) => filterStateChange(filter, ...params)}/>
          </Col>
        </Row>
      )}
    </>
  );
}

// filter: { name, key, multiple, options=[{ name, value }] }
function Filter({ filter, filterState, onFilterStateChange }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <span className="pointer" onClick={() => setIsOpen(!isOpen)}>{filter.name}
        {isOpen 
          ? <VscChevronUp className="ml-1"/> 
          : <VscChevronDown className="ml-1"/>
        }
      </span>
      <Collapse in={isOpen} className="pt-1">
        <Form>
          {filter.options.map(option =>
            <Form.Check name={filter.key} checked={filterState.includes(`${option.value}`)} value={option.value} key={option.value} type="checkbox" label={option.name} onChange={e => onFilterStateChange(e.target.value, e.target.checked)}/>
          )}
        </Form>
      </Collapse>
    </>
  );
}

export default Filters;