import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import http from '../../../../services/http';

function RemoveApiTokenModal({ show, onClose, onRemoveSuccess, apiToken }) {
  const onRemoveApiToken = () => {
    http.delete(`/auth/api-tokens/${apiToken.id}`)
      .then(() => {
        onRemoveSuccess();
        onClose();
      });
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {apiToken?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to delete the API token '{apiToken?.name}'?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={onRemoveApiToken}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemoveApiTokenModal;