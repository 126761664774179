import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useContext, useState } from 'react';
import axios from 'axios';
import { afterLogin } from '../services/http';
import config from '../config';
import { useHistory } from 'react-router-dom';
import authContext from '../contexts/authContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);

  const { setIsLoggedIn } = useContext(authContext);

  const history = useHistory();

  const onLogin = e => {
    e.preventDefault();
    e.stopPropagation();

    setIncorrectCredentials(false);

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    axios.post(`${config.apiUrl}/auth/login`, { email, password })
      .then(res => {
        setValidated(true);
        afterLogin(res.data.data.token);
        setIsLoggedIn(true);
        history.push('/');
      })
      .catch(() => {
        setIncorrectCredentials(true);
        setValidated(false);
      });
  }

  return (
    <>
      <h1 className="text-center pb-2">Login</h1>
      <Row className="justify-content-center">
        <Col md="6" sm="12">
          <Form noValidate validated={validated} onSubmit={onLogin}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" required onChange={e => setEmail(e.target.value)} isInvalid={incorrectCredentials}/>
              <Form.Control.Feedback type="invalid">
                {!incorrectCredentials && 'Please enter a valid email address'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" required onChange={e => setPassword(e.target.value)} isInvalid={incorrectCredentials}/>
              <Form.Control.Feedback type="invalid">
                {incorrectCredentials
                  ? 'Email or password incorrect'
                  : 'Please enter a password'
                }
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">Login</Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Login;