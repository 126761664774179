import PlayerFormModal from '../PlayerFormModal';
import http from '../../../../services/http';

function AddPlayerModal({ show, onClose, onAddSuccess }) {
  const onAddPlayer = player => {
    http.post('/players', player)
      .then(() => {
        onAddSuccess();
        onClose();
      });
  }

  return (
    <PlayerFormModal title="Add player" show={show} onClose={onClose} onSave={onAddPlayer}/>
  );
}

export default AddPlayerModal;