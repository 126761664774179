import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { VscAdd } from 'react-icons/vsc';
import http from '../../../../services/http';
import DataTable from '../../../DataTable';
import AddPlayerModal from '../AddPlayerModal';
import EditPlayerModal from '../EditPlayerModal';
import RemovePlayerModal from '../RemovePlayerModal';

function ManagePlayers() {
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const fetchPlayers = () => {
    http.get('/players')
      .then(res => setPlayers(res.data.data));
  }

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fields = [
    { name: 'ID', key: 'id' },
    { name: 'Username', key: 'username' }
  ];

  const onEdit = player => {
    setSelectedPlayer(player);
    setShowEditModal(true);
  }

  const onRemove = player => {
    setSelectedPlayer(player);
    setShowRemoveModal(true);
  }

  return (
    <>
      <h2 className="text-center">Manage players</h2>
      <Button variant="primary" className="mb-2" onClick={() => setShowAddModal(true)}><VscAdd/> Add</Button>
      <DataTable fields={fields} data={players} sorting edit remove onEdit={onEdit} onRemove={onRemove}/>
      <AddPlayerModal show={showAddModal} onClose={() => setShowAddModal(false)} onAddSuccess={fetchPlayers}/>
      <EditPlayerModal show={showEditModal} onClose={() => setShowEditModal(false)} onEditSuccess={fetchPlayers} player={selectedPlayer}/>
      <RemovePlayerModal show={showRemoveModal} onClose={() => setShowRemoveModal(false)} onRemoveSuccess={fetchPlayers} player={selectedPlayer}/>
    </>
  );
}

export default ManagePlayers;