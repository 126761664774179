import moment from 'moment';
import { useEffect, useState } from 'react';
import { VscAdd } from 'react-icons/vsc';
import Button from 'react-bootstrap/Button';
import http from '../../../../services/http';
import DataTable from '../../../DataTable';
import AddApiTokenModal from '../AddApiTokenModal';
import EditApiTokenModal from '../EditApiTokenModal';
import RemoveApiTokenModal from '../RemoveApiTokenModal';

function ManageApiTokens() {
  const [apiTokens, setApiTokens] = useState([]);
  const [selectedApiToken, setSelectedApiToken] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const fetchApiTokens = () => {
    http.get('/auth/api-tokens')
      .then(res => setApiTokens(res.data.data));
  }

  useEffect(() => {
    fetchApiTokens();
  }, [])

  const fields = [
    { name: 'ID', key: 'id' },
    { name: 'Name', key: 'name' },
    { name: 'Created at', key: 'created_at', render: val => moment(val * 1000).format('DD-MM-YYYY HH:mm:ss') }
  ]

  const onEdit = apiToken => {
    setSelectedApiToken(apiToken);
    setShowEditModal(true);
  }

  const onRemove = apiToken => {
    setSelectedApiToken(apiToken);
    setShowRemoveModal(true);
  }

  return (
    <>
      <h2 className="text-center">Manage API tokens</h2>
      <Button variant="primary" className="mb-2" onClick={() => setShowAddModal(true)}><VscAdd/> Add</Button>
      <DataTable fields={fields} data={apiTokens} sorting edit remove onEdit={onEdit} onRemove={onRemove}/>
      <AddApiTokenModal show={showAddModal} onClose={() => setShowAddModal(false)} onAddSuccess={fetchApiTokens}/>
      <EditApiTokenModal show={showEditModal} onClose={() => setShowEditModal(false)} onEditSuccess={fetchApiTokens} apiToken={selectedApiToken}/>
      <RemoveApiTokenModal show={showRemoveModal} onClose={() => setShowRemoveModal(false)} onRemoveSuccess={fetchApiTokens} apiToken={selectedApiToken}/>
    </>
  )
}

export default ManageApiTokens;