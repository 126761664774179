import ApiTokenFormModal from '../ApiTokenFormModal';
import http from '../../../../services/http';

function EditApiTokenModal({ show, onClose, onEditSuccess, apiToken }) {
  const onEditApiToken = _apiToken => {
    http.put(`/auth/api-tokens/${apiToken.id}`, _apiToken)
      .then(() => {
        onEditSuccess();
        onClose();
      });
  }

  return (
    <ApiTokenFormModal title="Edit API token" show={show} onClose={onClose} onSave={onEditApiToken} apiToken={apiToken}/>
  );
}

export default EditApiTokenModal;