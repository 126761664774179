import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import http from '../../../../services/http';

function RemovePlayerModal({ show, onClose, onRemoveSuccess, player }) {
  const onRemovePlayer = () => {
    http.delete(`/players/${player.id}`)
      .then(() => {
        onRemoveSuccess();
        onClose();
      });
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {player?.username}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to delete the player '{player?.username}'?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={onRemovePlayer}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemovePlayerModal;