import { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import ManagePlayers from '../components/admin/players/ManagePlayers';
import authContext from '../contexts/authContext';
import ManageApiTokens from '../components/admin/api-tokens/ManageApiTokens';

function Admin() {
  const { isLoggedIn } = useContext(authContext);

  const history = useHistory();
  if (!isLoggedIn) {
    history.push('/');
  }

  return (
    <>
      <h1 className="text-center pb-2">Admin</h1>
      <Row>
        <Col md="6" sm="12" className="mb-3 mb-md-0">
          <ManagePlayers/>
        </Col>
        <Col md="6" sm="12">
          <ManageApiTokens/>
        </Col>
      </Row>
    </>
  );
}

export default Admin;